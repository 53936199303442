<template>
  <v-container class="max-w-100">
    <router-view />
  </v-container>
</template>

<script>
export default {
  name: 'Page',
}
</script>

<style scoped>

.container {
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 0px;
  margin: 0px;
  border: 0px;
}
</style>
